import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import environment from 'src/app/app.config';
import { SnackbarService } from 'src/app/library/snackbar';
import { ApiError } from '../classes/api-error.class';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private _loginURL: string = `${environment.prodSuiteUrl}/login`;

  constructor(private _snackbarService: SnackbarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpResponseError: HttpErrorResponse) => {
        // parse error
        const parsedError = this._parseError(httpResponseError);

        switch (httpResponseError.status) {
          case 401: {
            /** 401 UNAUTHORIZED */
            this._sendToLogin();
            break;
          }
          case 403: {
            /** 403 FORBIDDEN */
            // display a snackbar when a 403 is encountered.
            this._snackbarService.error(
              'Insufficient Access: Some functionality on this page may not work as expected'
            );

            break;
          }
        }

        return throwError(parsedError);
      })
    );
  }

  private _parseError(httpResponseError: HttpErrorResponse): ApiError {
    // Use Angular error message as default
    let message: string = httpResponseError.message;

    try {
      // API will return error in a couple different ways :(

      const error = JSON.parse(httpResponseError.error);
      if (typeof error?.status === 'object') {
        // "{"content":{},"status":{"code":0,"message":"Some error message"}}"
        message = error.status.message;
      } else if (typeof error?.err === 'string') {
        // "{"err":"Error: Some error message"}"
        message = error.err;
      }
      return error;
    } catch (e) {}

    return new ApiError(message, httpResponseError.status);
  }

  private _sendToLogin() {
    window.location.assign(this._loginURL);
  }
}
