import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import environment from 'src/app/app.config';
import { RequestOptions, ResponseContentType } from '../models/http.interface';

@Injectable()
export class ApiService {
  apiURL: string;
  notificationApiUrl: string;

  private headers: HttpHeaders;
  private options: RequestOptions;
  private _defaultOptions: {};

  constructor(private _http: HttpClient) {
    this.apiURL = environment.apiUrl;
    this.notificationApiUrl = environment.notifications.apiUrl;

    this.headers = new HttpHeaders({
      'Content-Type': ResponseContentType.Json,
      Accept: ResponseContentType.Json
    });

    this._defaultOptions = {
      headers: this.headers,
      observe: 'response',
      responseType: 'text',
      withCredentials: true
    };

    this.options = this._defaultOptions;
  }

  getNotifications(path: string, options?: {}): Observable<any> {
    const url = `${this.notificationApiUrl}/client${path}`;
    return this._http
      .get<HttpResponse<any>>(url, options ? this.customOptions(options) : this.options)
      .pipe(map(this._parseBody));
  }

  putNotifications<T = any>(path: string, body: T): Observable<any> {
    return this._http
      .put<HttpResponse<any>>(
        `${this.notificationApiUrl}/client${path}`,
        JSON.stringify(body),
        this.options
      )
      .pipe(map(this._parseBody));
  }

  get<T = any>(path: string, options?: {}): Observable<T> {
    return this._http
      .get<HttpResponse<T>>(
        `${this.apiURL}${path}`,
        options ? this.customOptions(options) : this.options
      )
      .pipe(map(this._parseBody));
  }

  // use for an endpoint that returns a response with type of `text/csv`
  getCSV(path: string, options?: {}): Observable<any> {
    const newOptions = Object.assign({}, { ...options, responseType: ResponseContentType.Text });

    return this._http
      .get<HttpResponse<any>>(`${this.apiURL}${path}`, this.customOptions(newOptions))
      .pipe(map(this._parseBody));
  }

  // use for an endpoint that returns a response with type of Blob
  getBlob(path: string, options?: {}): Observable<any> {
    const newOptions = Object.assign({}, { ...options, responseType: ResponseContentType.Blob });

    return this._http
      .get<HttpResponse<any>>(`${this.apiURL}${path}`, this.customOptions(newOptions))
      .pipe(map(this._parseBody));
  }

  post<T = any, B = any>(path: string, body: B, options?: {}): Observable<T> {
    const emptyHeadersOption = { headers: new Headers({}) };
    let requestBody: FormData | string;
    if (body instanceof FormData) {
      options = options ? Object.assign(options, emptyHeadersOption) : emptyHeadersOption;
      requestBody = body;
    } else {
      requestBody = JSON.stringify(body);
    }

    return this._http
      .post<HttpResponse<T>>(
        `${this.apiURL}${path}`,
        requestBody,
        options ? this.customOptions(options) : this.options
      )
      .pipe(map(this._parseBody));
  }

  put<T = any, B = any>(path: string, body: B): Observable<T> {
    return this._http
      .put<HttpResponse<T>>(`${this.apiURL}${path}`, JSON.stringify(body), this.options)
      .pipe(map(this._parseBody));
  }

  delete<T = any>(path: string): Observable<T> {
    return this._http.delete<T>(`${this.apiURL}${path}`, this.options);
  }

  setHeaders(headers: {}) {
    Object.keys(headers).forEach((header) => this.headers.set(header, headers[header]));
  }

  private _parseBody(response: HttpResponse<any>) {
    // if response is not a valid json object just return an empty json object.
    try {
      // handle when the response is a Blob we need to return the entire body
      if (response && response.body instanceof Blob) {
        return response.body;
        // otherwise we treat it as a JSON body
      } else {
        return JSON.parse(response.body);
      }
    } catch (e) {
      return {};
    }
  }

  // merge the options parameters with the default options, overwriting
  // any options in default with the newOptions
  private customOptions(newOptions: {}): {} {
    const optionsObj: RequestOptions = {};
    Object.assign(optionsObj, this._defaultOptions, newOptions);

    return optionsObj;
  }
}
