import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { AuthStorageService } from '@becksdevteam/okta-angular';

import config from 'src/app/app.config';

import {
  EBatchOperation,
  INotification
} from '../../shared/models/data-model/notifications.interface';
import { IUser } from '../../shared/models/data-model/user.interface';
import { INotificationUserInfoVM } from '../../shared/models/view-model/notifications-vm.interface';

import { SocketIO } from '../../shared/classes/socket-io.class';
import { ApiService } from './api.service';

@Injectable()
export class NotificationsService {
  private _socket: SocketIO;
  private _token: string;

  // reconnect
  socketReconnected$: Observable<number>;
  // new notification
  newNotification$: Observable<INotification>;

  constructor(private _apiService: ApiService, private _storageService: AuthStorageService) {
    this._token = this._storageService.getToken();
    this._socket = new SocketIO(config.notifications.socket, this._token);
    this._socket.connect();

    this.socketReconnected$ = this._socket.onEvent('reconnect');
    this.newNotification$ = this._socket.onEvent('notification');
  }

  join() {
    this._socket.send('handshake', { appCode: config.appCode, token: this._token });
    return of(this._socket);
  }

  setAll(code: EBatchOperation, userInfo: INotificationUserInfoVM): Observable<void> {
    return this._apiService.putNotifications(
      `/notification/allnotifications/${code}?applicationCode=${config.appCode}`,
      userInfo
    );
  }

  setSeen(notificationID: number, userInfo: INotificationUserInfoVM): Observable<void> {
    return this._apiService.putNotifications(`/notification/seen/${notificationID}`, userInfo);
  }

  setClear(notificationID: number, userInfo: INotificationUserInfoVM): Observable<void> {
    return this._apiService.putNotifications(`/notification/clear/${notificationID}`, userInfo);
  }

  fetchNotifications(user: IUser): Observable<INotification[]> {
    return this._apiService.getNotifications(
      `/notification/${user.id}?applicationCode=${config.appCode}`
    );
  }
}
