import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { EAppModule } from 'src/app/shared/models/data-model/app-module.interface';
import {
  IUser,
  IUserPermissions,
  IUserPreferences
} from 'src/app/shared/models/data-model/user.interface';
import { UserActionTypes, UserActions } from '../actions/user.actions';

const defaultUser: IUser = {
  email: '',
  firstName: '',
  lastName: '',
  pictureUrl: '',
  userName: ''
};

// Database does not have a preferences type per app yet
const defaultPreferenceTypes: { [key: string]: string[] } = {
  [EAppModule.capps]: [],
  [EAppModule.seedtracker]: ['quickLotPrefix']
};

export interface UserState {
  model: {
    user: IUser;
    permissions: IUserPermissions;
    preferences: IUserPreferences;
    preferenceTypes: { [key: string]: string[] };
    regions: number[];
  };
  viewModel: {
    userDeSync: IUser | undefined;
    preferencesForm: FormGroup;
    userLoaded: boolean;
    userLoading: boolean;
    userErred: boolean;
    permissionsLoaded: boolean;
    permissionsLoading: boolean;
    permissionsErred: boolean;
    userRegionsLoaded: boolean;
    userRegionsLoading: boolean;
    userRegionsErred: boolean;
    preferencesLoaded: boolean;
    preferencesLoading: boolean;
    preferencesErred: boolean;
  };
}

export const initialState: UserState = {
  model: {
    user: defaultUser,
    permissions: {},
    preferences: {},
    preferenceTypes: defaultPreferenceTypes,
    regions: []
  },
  viewModel: {
    userDeSync: undefined,
    preferencesForm: new FormGroup({}),
    userLoaded: false,
    userLoading: false,
    userErred: false,
    permissionsLoaded: false,
    permissionsLoading: false,
    permissionsErred: false,
    userRegionsLoaded: false,
    userRegionsLoading: false,
    userRegionsErred: false,
    preferencesLoaded: false,
    preferencesLoading: false,
    preferencesErred: false
  }
};

export function reducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    // user object
    case UserActionTypes.InitializeUserAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userLoading: true,
          userLoaded: false,
          userErred: false
        }
      };
    }
    case UserActionTypes.InitializeUserFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userLoaded: false,
          userLoading: false,
          userErred: true
        }
      };
    }
    case UserActionTypes.InitializeUserSuccessAction: {
      const user = action.payload;
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userLoaded: true,
          userLoading: false,
          userErred: false
        },
        model: {
          ...state.model,
          user
        }
      };
    }

    // Permissions map
    case UserActionTypes.LoadUserPermissionsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          permissionsLoading: true,
          permissionsLoaded: false,
          permissionsErred: false
        }
      };
    }
    case UserActionTypes.LoadUserPermissionsFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          permissionsLoaded: false,
          permissionsLoading: false,
          permissionsErred: true
        }
      };
    }
    case UserActionTypes.LoadUserPermissionsSuccessAction: {
      const permissions = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          permissionsLoaded: true,
          permissionsLoading: false,
          permissionsErred: false
        },
        model: {
          ...state.model,
          permissions
        }
      };
    }

    // UserRegions map
    case UserActionTypes.LoadUserRegionsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userRegionsLoaded: false,
          userRegionsLoading: true,
          userRegionsErred: false
        }
      };
    }
    case UserActionTypes.LoadUserRegionsFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userRegionsLoaded: false,
          userRegionsLoading: false,
          userRegionsErred: true
        }
      };
    }
    case UserActionTypes.LoadUserRegionsSuccessAction: {
      const regions = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userRegionsLoaded: true,
          userRegionsLoading: false,
          userRegionsErred: false
        },
        model: {
          ...state.model,
          regions
        }
      };
    }

    // Preferences
    case UserActionTypes.LoadUserPreferencesAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          preferencesLoaded: false,
          preferencesLoading: true,
          preferencesErred: false
        }
      };
    }
    case UserActionTypes.LoadUserPreferencesFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          preferencesLoaded: false,
          preferencesLoading: false,
          preferencesErred: true
        }
      };
    }
    case UserActionTypes.LoadUserPreferencesSuccessAction: {
      const preferences = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          preferencesLoaded: true,
          preferencesLoading: false,
          preferencesErred: false
        },
        model: {
          ...state.model,
          preferences
        }
      };
    }

    case UserActionTypes.SaveUserPreferencesSuccessAction: {
      const preferences = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          preferences
        }
      };
    }

    // Preferences form
    case UserActionTypes.InitializePreferencesFormCompleteAction: {
      const copyPreferencesForm = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          preferencesForm: copyPreferencesForm
        }
      };
    }
    case UserActionTypes.UpdatePreferencesFormAction: {
      const updatePreferencesForm = _.cloneDeep(action.payload);

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          preferencesForm: updatePreferencesForm
        }
      };
    }
    case UserActionTypes.PreferencesFormChangedAction: {
      const updatePreferencesForm = _.cloneDeep(action.payload);

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          preferencesForm: updatePreferencesForm
        }
      };
    }
    case UserActionTypes.ResetPreferencesFormAction: {
      const preferencesForm = _.cloneDeep(action.payload);
      preferencesForm.reset(preferencesForm.value);

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          preferencesForm
        }
      };
    }

    case UserActionTypes.ResetUserStateAction: {
      return initialState;
    }

    // user de sync
    case UserActionTypes.SetUserDeSyncAction: {
      const userDeSync = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userDeSync
        }
      };
    }
    case UserActionTypes.ResetUserDeSyncAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userDeSync: null
        }
      };
    }

    default:
      return state;
  }
}

export const getUser = (state: UserState) => state.model.user;
export const getUserLoaded = (state: UserState) => state.viewModel.userLoaded;
export const getUserLoading = (state: UserState) => state.viewModel.userLoading;
export const getUserErred = (state: UserState) => state.viewModel.userErred;
export const getUserPermissions = (state: UserState) => state.model.permissions;
export const getUserPermissionsLoaded = (state: UserState) => state.viewModel.permissionsLoaded;
export const getUserPermissionsLoading = (state: UserState) => state.viewModel.permissionsLoading;
export const getUserPermissionsErred = (state: UserState) => state.viewModel.permissionsErred;
export const getUserRegions = (state: UserState) => state.model.regions;
export const getUserRegionsLoaded = (state: UserState) => state.viewModel.userRegionsLoaded;
export const getUserRegionsLoading = (state: UserState) => state.viewModel.userRegionsLoading;
export const getUserRegionsErred = (state: UserState) => state.viewModel.userRegionsErred;
export const getUserPreferences = (state: UserState) => state.model.preferences;
export const getUserPreferencesLoaded = (state: UserState) => state.viewModel.preferencesLoaded;
export const getUserPreferencesLoading = (state: UserState) => state.viewModel.preferencesLoading;
export const getUserPreferencesErred = (state: UserState) => state.viewModel.preferencesErred;
export const getUserPreferenceTypes = (state: UserState) => state.model.preferenceTypes;
export const getUserPreferencesForm = (state: UserState) => state.viewModel.preferencesForm;
export const getUserDeSync = (state: UserState) => state.viewModel.userDeSync;
