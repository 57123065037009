import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import config from 'src/app/app.config';
import {
  IRTEventPayload,
  IRTNotificationEventPayload,
  IRTSocketOptions
} from '../models/data-model/realtime.interface';

const isNotification = (
  payload: IRTEventPayload<any> | IRTNotificationEventPayload<any>
): payload is IRTNotificationEventPayload<any> => {
  return (payload as IRTNotificationEventPayload<any>).acknowledgementID !== undefined;
};

export class SocketIO {
  private _socket: SocketIOClient.Socket;
  private _connectionOptions: SocketIOClient.ConnectOpts;

  public get socket() {
    return this._socket;
  }

  constructor(public options: IRTSocketOptions = config.socket, private _token: string) {
    this._connectionOptions = {
      forceNew: true,
      transports: ['websocket'],
      path: this.options.path
    };
  }

  public connect(featurePath?: string) {
    let url: string;
    if (featurePath) {
      url = `${this.options.url}/${featurePath}`;
    } else {
      url = this.options.url;
    }

    // creates and connects
    this._socket = io(url, this._connectionOptions);
  }

  public disconnect() {
    this._socket.close();
  }

  public send(event: string, ...args: unknown[]) {
    this._socket.emit(event, ...args);
  }

  public join(resource: string, ids: (number | string)[]) {
    this._socket.emit('handshake', { resource, ids, token: this._token, type: 'client' });
  }

  public leave(resource: string, ids: (number | string)[]) {
    this._socket.emit('leave', { resource, ids });
  }

  public unsubscribe() {
    this._socket.emit('leave-all');
  }

  public acknowledge(ids: string[]) {
    this._socket.emit('acknowledgement', ids);
  }

  public onEvent(event: string): Observable<any> {
    return new Observable<any>((obs) => {
      this._socket.on(event, (payload: IRTEventPayload<any> | IRTNotificationEventPayload<any>) => {
        obs.next(payload.message);

        // ack packet
        if (typeof payload === 'object') {
          if (isNotification(payload)) {
            this.acknowledge([payload.acknowledgementID]);
          } else {
            this.acknowledge([payload.ID]);
          }
        }
      });
    });
  }
}
