import { HttpHeaders } from '@angular/common/http';

export enum ResponseContentType {
  ArrayBuffer = 'arrayBuffer',
  Blob = 'Blob',
  Json = 'application/json',
  Text = 'text/plain',
  Pdf = 'application/pdf'
}

export interface RequestOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  body?: any;
}
