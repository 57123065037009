import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { DialogService } from './services/dialog.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  entryComponents: [ConfirmationDialogComponent]
})
export class ConfirmationDialogModule {
  static forRoot(): ModuleWithProviders<ConfirmationDialogModule> {
    return {
      ngModule: ConfirmationDialogModule,
      providers: [DialogService]
    };
  }
}
