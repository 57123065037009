import { FormGroup, FormArray } from '@angular/forms';

export function sanitizeForm(formGroup: FormGroup | FormArray): {} {
  if (formGroup) {
    return {
      // intentionally leaving out `controls`... this was one of the culprits that locked up redux dev tools
      dirty: formGroup.dirty,
      errors: formGroup.errors,
      invalid: formGroup.invalid,
      pristine: formGroup.pristine,
      status: formGroup.status,
      touched: formGroup.touched,
      valid: formGroup.valid,
      value: formGroup.value
    };
  } else {
    return {};
  }
}
