import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OktaAuthService } from '@becksdevteam/okta-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { SnackbarService } from 'src/app/library/snackbar';
import { extractFeatures } from 'src/app/shared/helpers/features.helper';
import { updateTreeValidity } from 'src/app/shared/helpers/form.helper';
import { IUser } from 'src/app/shared/models/data-model/user.interface';
import * as fromActions from '../actions';
import { UserActionTypes } from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';

@Injectable()
export class UserEffects {
  constructor(
    private _actions$: Actions,
    private _userService: UserService,
    private _oktaService: OktaAuthService<IUser>,
    private _snackbarService: SnackbarService,
    private _store: Store<fromReducers.GlobalState>,
    private _fb: FormBuilder
  ) {}

  initializeUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.InitializeUserAction),
      switchMap(() => {
        return from(this._oktaService.getUser()).pipe(
          map((user) => {
            return new fromActions.InitializeUserSuccess(user);
          }),
          catchError((error) => {
            this._snackbarService.error(`Error Initializing User: ${error.message}`);
            return of(new fromActions.InitializeUserFail(error));
          })
        );
      })
    )
  );

  loadUserPermissions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.LoadUserPermissionsAction),
      switchMap(() => {
        return this._userService.fetchUserPermissions().pipe(
          map((modules) => {
            const security = extractFeatures(modules);
            return new fromActions.LoadUserPermissionsSuccess(security);
          }),
          catchError((error) => {
            if (error.status.code !== 403) {
              this._snackbarService.error('Error Fetching User Permissions');
            }
            return of(new fromActions.LoadUserPermissionsFail(error));
          })
        );
      })
    )
  );

  loadUserRegions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.LoadUserRegionsAction),
      switchMap(() => {
        return this._userService.fetchUserRegions().pipe(
          map(({ regionIDs }) => {
            return new fromActions.LoadUserRegionsSuccess(regionIDs);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Fetching User Regions');
            return of(new fromActions.LoadUserRegionsFail(error));
          })
        );
      })
    )
  );

  loadUserPreferences$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.LoadUserPreferencesAction),
      switchMap(() => {
        return this._userService.fetchUserPreferences().pipe(
          map((preferences) => {
            return new fromActions.LoadUserPreferencesSuccess(preferences);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Fetching User Preferences');
            return of(new fromActions.LoadUserPreferencesFail(error));
          })
        );
      })
    )
  );

  saveUserPreferences$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.SaveUserPreferencesAction),
      map((action: fromActions.SaveUserPreferences) => action.payload),
      withLatestFrom(
        this._store.select(fromSelectors.getSystemInfoActiveAppModule),
        this._store.select(fromSelectors.getUserPreferences)
      ),
      switchMap(([appPreferences, appModule, currentPreferences]) => {
        // replace app preferences in global object
        const preferences = {
          ...currentPreferences,
          [appModule]: appPreferences
        };

        return this._userService.saveUserPreferences(preferences).pipe(
          map(() => {
            this._snackbarService.success('Preferences Successfully Saved');
            return new fromActions.SaveUserPreferencesSuccess(preferences);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Saving User Preferences');
            return of(new fromActions.SaveUserPreferencesFail(error));
          })
        );
      })
    )
  );

  initializeNewFormAfterNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((navigated: RouterNavigatedAction) => navigated),
      filter((action) => action.payload.event.url.endsWith('/preferences/general')),
      switchMap(() => of(new fromActions.InitializePreferencesForm()))
    )
  );

  initializePreferencesForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.InitializePreferencesFormAction),
      withLatestFrom(
        this._store.select(fromSelectors.getAppModuleUserPreferences),
        this._store.select(fromSelectors.getAppModuleUserPreferenceTypes)
      ),
      switchMap(([action, userPreferences, preferenceTypes]) => {
        const form: FormGroup = this._fb.group({});

        preferenceTypes.forEach((type) => {
          form.addControl(type, this._fb.control(userPreferences[type]));
        });

        updateTreeValidity(form);

        return of(new fromActions.InitializePreferencesFormComplete(form));
      })
    )
  );

  resetPreferencesForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.SaveUserPreferencesSuccessAction),
      withLatestFrom(
        this._store.select(fromSelectors.getAppModuleUserPreferences),
        this._store.select(fromSelectors.getAppModuleUserPreferenceTypes)
      ),
      switchMap(([action, userPreferences, preferenceTypes]) => {
        const form: FormGroup = this._fb.group({});

        preferenceTypes.forEach((type) => {
          form.addControl(type, this._fb.control(userPreferences[type]));
        });

        updateTreeValidity(form);

        return of(new fromActions.ResetPreferencesForm(form));
      })
    )
  );

  resetPreferencesFormProperty$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.ResetPreferencesFormPropertyAction),
      map((action: fromActions.ResetPreferencesFormProperty) => action.payload),
      withLatestFrom(this._store.select(fromSelectors.getUserPreferencesForm)),
      switchMap(([property, preferencesForm]) => {
        const newPreferencesForm = _.cloneDeep(preferencesForm);
        newPreferencesForm.get(property).reset();

        return of(new fromActions.UpdatePreferencesForm(newPreferencesForm));
      })
    )
  );

  userNotAuthorized$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActionTypes.UserNotAuthorizedAction),
      switchMap(() => {
        this._snackbarService.error('You are not authorized to view the requested page.');
        return of(new fromActions.RouteToHomeAppPage());
      })
    )
  );

  userChanged$ = createEffect(() =>
    this._oktaService.user$.pipe(
      withLatestFrom(
        this._store.select(fromSelectors.getUserIsLoggedIn),
        this._store.select(fromSelectors.getUser),
        this._store.select(fromSelectors.getUserDeSync)
      ),
      // if there is no user initialized, stop here
      filter(([newUser, isLoggedIn]) => isLoggedIn),
      switchMap(([newUser, isLoggedIn, user, userDeSync]) => {
        // If another user is logged in, this
        // will present a modal with the options to restart as the new user or logout.
        // different user was detected
        if (newUser.id !== user.id && (!userDeSync || userDeSync.id !== newUser.id)) {
          return of(new fromActions.SetUserDeSync(newUser));
        } else {
          // original user detected, after having reacted to a different user
          return of(new fromActions.ResetUserDeSync());
        }
      })
    )
  );
}
