import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { Router } from '@angular/router';
/** Becks Okta */
import {
  IOktaConfig,
  IOktaSession,
  OktaAuthHttpInterceptor,
  OktaAuthModule,
  OktaAuthService
} from '@becksdevteam/okta-angular';
import config from 'src/app/app.config';
import { SnackbarService } from 'src/app/library/snackbar';
import { IUser } from 'src/app/shared/models/data-model/user.interface';
import { AuthRoutingModule } from './auth.routing';
import { guards } from './guards';

const appBaseUrl = config.prodSuiteUrl;
const redirectUri = `${appBaseUrl}/implicit/callback`;

const onAuthRequired = (_oktaService: OktaAuthService, injector: Injector) => {
  // send user to login
  const router = injector.get(Router);
  router.navigate(['/login']);
};

const isSameSession = async (beckUser: IUser, session: IOktaSession) => {
  return beckUser.okta?.external.sessionID === session.id;
};

const onUserForbidden = (_oktaService: OktaAuthService, injector: Injector) => {
  // display message
  const snackbarService = injector.get(SnackbarService);
  snackbarService.error(
    'You do not have access to Production Suite: Please, contact the Help Desk.'
  );

  // send user to login
  const router = injector.get(Router);
  router.navigate(['/login']);
};

const oktaConfig: IOktaConfig = {
  auth: {
    issuer: `${config.okta.externalOrgUrl}/oauth2/default`,
    clientId: config.okta.clientId,
    redirectUri,
    postLogoutRedirectUri: appBaseUrl,
    pkce: false
  },
  widget: {
    baseUrl: config.okta.externalOrgUrl,
    redirectUri,
    features: {
      idpDiscovery: true,
      registration: false
    }
  },
  appBaseUrl,
  authToken: config.authTokenName,
  endpoints: {
    baseUrl: config.apiUrl,
    session: {
      validate: `/auth/sessions/validate`,
      terminate: '/auth/sessions/terminate',
      getInternal: `${config.okta.internalOrgUrl}/api/v1/sessions/me`
    },
    token: {
      verify: `${config.authApiUrl}/tokens/apps/verify`
    }
  },
  isSameSession,
  onAuthRequired,
  onUserForbidden
};

@NgModule({
  imports: [OktaAuthModule.forRoot(oktaConfig), AuthRoutingModule],
  providers: [
    ...guards,
    { provide: HTTP_INTERCEPTORS, useClass: OktaAuthHttpInterceptor, multi: true }
  ]
})
export class AuthModule {}
