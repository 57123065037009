export interface INotification {
  ID: number;
  groupCode: string;
  link: string;
  message: string;
  notificationSeen: boolean;
  notifierBeckUserId: number;
  title: string;
  typeCode: string;
  date: string;
}

export enum EBatchOperation {
  seen = 'SEEN',
  cleared = 'CLEARED'
}
