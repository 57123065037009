import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/** Components */
import {
  LoginComponent,
  LogoutComponent,
  ImplicitCallbackComponent,
  OktaProtectLoginGuard
} from '@becksdevteam/okta-angular';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    canActivate: [OktaProtectLoginGuard]
  },
  {
    path: 'logout',
    pathMatch: 'full',
    component: LogoutComponent
  },
  {
    path: 'implicit/callback',
    pathMatch: 'full',
    component: ImplicitCallbackComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
