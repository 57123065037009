import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { SnackbarService } from 'src/app/library/snackbar';
import {
  EBatchOperation,
  INotification
} from 'src/app/shared/models/data-model/notifications.interface';
import { INotificationUserInfoVM } from 'src/app/shared/models/view-model/notifications-vm.interface';
import * as fromActions from '../actions';
import { NotificationsActionTypes } from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';

@Injectable()
export class NotificationsEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromReducers.GlobalState>,
    private _notificationsService: NotificationsService,
    private _snackbarService: SnackbarService,
    private _router: Router
  ) {}

  handleInitializeNotificationsSocketConnection$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActionTypes.InitializeNotificationsSocketConnectionAction),
      switchMap(() => {
        return this._notificationsService.join().pipe(
          map((socket) => new fromActions.InitializeNotificationsSocketConnectionSuccess()),
          catchError((error) =>
            of(new fromActions.InitializeNotificationsSocketConnectionFail(error))
          )
        );
      })
    )
  );

  reInitializeNotificationsSocketConnection$ = createEffect(() =>
    this._notificationsService.socketReconnected$.pipe(
      switchMap((attemptNumber) => of(new fromActions.InitializeNotificationsSocketConnection()))
    )
  );

  loadNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActionTypes.LoadNotificationsAction),
      withLatestFrom(this._store.select(fromSelectors.getUser)),
      switchMap(([action, user]) => {
        return this._notificationsService.fetchNotifications(user).pipe(
          map(
            (notifications: INotification[]) =>
              new fromActions.LoadNotificationsSuccess(notifications)
          ),
          catchError((error) => {
            return of(new fromActions.LoadNotificationsFail(error));
          })
        );
      })
    )
  );

  newNotification$ = createEffect(() =>
    this._notificationsService.newNotification$.pipe(
      filter((payload) => !!payload),
      switchMap((notification) => {
        const date = new Date();
        notification.date = date.toISOString();

        return of(new fromActions.AddNotificationToEntities(notification));
      })
    )
  );

  setSeenNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActionTypes.SetSeenNotificationAction),
      map((action: fromActions.SetSeenNotification) => action.payload),
      withLatestFrom(this._store.select(fromSelectors.getUser)),
      switchMap(([notification, user]) => {
        const userInfo: INotificationUserInfoVM = {
          id: user.id,
          userName: user.userName
        };
        return this._notificationsService.setSeen(notification.ID, userInfo).pipe(
          map(() => new fromActions.SetSeenNotificationSuccess(notification)),
          catchError((error) => {
            this._snackbarService.error('Error Setting Notification as Seen');
            return of(new fromActions.SetSeenNotificationFail(error));
          })
        );
      })
    )
  );

  setClearNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActionTypes.SetClearNotificationAction),
      map((action: fromActions.SetClearNotification) => action.payload),
      withLatestFrom(this._store.select(fromSelectors.getUser)),
      switchMap(([notification, user]) => {
        const userInfo: INotificationUserInfoVM = {
          id: user.id,
          userName: user.userName
        };
        return this._notificationsService.setClear(notification.ID, userInfo).pipe(
          map(() => new fromActions.SetClearNotificationSuccess(notification)),
          catchError((error) => {
            this._snackbarService.error('Error Clearing Notification');
            return of(new fromActions.SetClearNotificationFail(error));
          })
        );
      })
    )
  );

  setSeenNotificationBatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActionTypes.SetSeenNotificationBatchAction),
      withLatestFrom(this._store.select(fromSelectors.getUser)),
      switchMap(([action, user]) => {
        const userInfo: INotificationUserInfoVM = {
          id: user.id,
          userName: user.userName
        };
        return this._notificationsService.setAll(EBatchOperation.seen, userInfo).pipe(
          map(() => new fromActions.SetSeenNotificationBatchSuccess()),
          catchError((error) => {
            this._snackbarService.error('Error Setting All Notifications as Seen');
            return of(new fromActions.SetSeenNotificationBatchFail(error));
          })
        );
      })
    )
  );

  setClearNotificationBatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActionTypes.SetClearNotificationBatchAction),
      withLatestFrom(this._store.select(fromSelectors.getUser)),
      switchMap(([action, user]) => {
        const userInfo: INotificationUserInfoVM = {
          id: user.id,
          userName: user.userName
        };
        return this._notificationsService.setAll(EBatchOperation.cleared, userInfo).pipe(
          map(() => new fromActions.SetClearNotificationBatchSuccess()),
          catchError((error) => {
            this._snackbarService.error('Error Clearing All Notifications');
            return of(new fromActions.SetClearNotificationBatchFail(error));
          })
        );
      })
    )
  );

  closeNotificationAfterNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(this._store.select(fromSelectors.getNotificationsShowNotifications)),
      filter(([action, showNotifications]) => showNotifications),
      switchMap(() => {
        return of(new fromActions.HideNotifications());
      })
    )
  );

  startAnimation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActionTypes.AddNotificationToEntitiesAction),
      switchMap(() => {
        return of(new fromActions.StartNotificationBadgeAnimation());
      })
    )
  );

  stopAnimation$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(NotificationsActionTypes.StartNotificationBadgeAnimationAction),
        map(() => {
          setTimeout(() => {
            this._store.dispatch(new fromActions.StopNotificationBadgeAnimation());
          }, 600);
        })
      ),
    { dispatch: false }
  );

  navigateToNotificationLink$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(NotificationsActionTypes.NavigateToNotificationLinkAction),
        map((action: fromActions.NavigateToNotificationLink) => action.payload),
        map((notification) => {
          const link = notification.link;

          if (link.indexOf('http:') >= 0 || link.indexOf('https:') >= 0) {
            window.location.href = link;
            return;
          }

          const routeSegments = link.split('/');
          const filteredRouteSegments = routeSegments.filter((a) => a !== 'app' && a !== '');
          const finalSegments: any[] = [];
          const queryParams: {} = {};

          filteredRouteSegments.forEach((seg: string) => {
            if (seg.indexOf(';') < 0 && seg.indexOf('?') < 0) {
              // This segment contains no matrix or query params
              finalSegments.push(seg);
            } else if (seg.indexOf('?') >= 0) {
              // This segment contains query parameters
              const routeSplit: string[] = seg.split('?');

              // routeSplit[0] is the last section of the actual route, before the query parms start
              finalSegments.push(routeSplit[0]);

              // routeSplit[1] is the query parameters, separated by an ampersand
              const paramSplit = routeSplit[1].split('&');
              paramSplit.forEach((param: string) => {
                const key: string = param.split('=')[0];
                const value: string = param.split('=')[1];
                queryParams[key] = value;
              });
            } else {
              // this segment contains matrix parameters,
              // so we must build an object to pass along to the router.navigate
              const paramSplit: string[] = seg.split(';');

              const p = paramSplit.shift();
              finalSegments.push(p);

              const paramObject = {};

              paramSplit.forEach((param: string) => {
                const key: string = param.split('=')[0];
                const value: string = param.split('=')[1];
                paramObject[key] = value;
              });
              finalSegments.push(paramObject);
            }
          });

          this._router.navigate(finalSegments, { queryParams });
        })
      ),
    { dispatch: false }
  );
}
