import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@becksdevteam/okta-angular';
import { UserRegionsGuard } from 'src/app/core/auth/guards/user-regions.guard';
/** Guards */
import { PermissionsGuard } from './core/auth/guards/permissions.guard';
import { UserPreferencesGuard } from './core/guards/user-preferences.guard';
import { UserGuard } from './core/guards/user.guard';
/** Components */
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [OktaAuthGuard, UserGuard, PermissionsGuard]
  },
  {
    path: 'capps',
    loadChildren: () => import('./capps/capps.module').then((m) => m.CappsModule),
    canActivate: [
      OktaAuthGuard,
      UserGuard,
      UserRegionsGuard,
      UserPreferencesGuard,
      PermissionsGuard
    ],
    data: {
      module: 'capps'
    }
  },
  {
    path: 'seedtracker',
    loadChildren: () => import('./seedtracker/seedtracker.module').then((m) => m.SeedtrackerModule),
    canActivate: [
      OktaAuthGuard,
      UserGuard,
      UserRegionsGuard,
      UserPreferencesGuard,
      PermissionsGuard
    ],
    data: {
      module: 'seedtracker'
    }
  },
  {
    path: 'labtracker',
    loadChildren: () => import('./labtracker/labtracker.module').then((m) => m.LabTrackerModule),
    canActivate: [
      OktaAuthGuard,
      UserGuard,
      UserRegionsGuard,
      UserPreferencesGuard,
      PermissionsGuard
    ],
    data: {
      module: 'labtracker'
    }
  },
  {
    path: 'userMgmt',
    loadChildren: () =>
      import('./user-management/user-management.module').then((m) => m.UserManagementModule),
    canActivate: [OktaAuthGuard, UserGuard, UserPreferencesGuard, PermissionsGuard]
  },
  {
    path: 'documentation',
    loadChildren: () =>
      import('./documentation/documentation.module').then((m) => m.DocumentationModule)
  },
  // default catch-all redirect
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
