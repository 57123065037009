import { AbstractControl, FormGroup, FormArray } from '@angular/forms';

export function updateTreeValidity(control: AbstractControl) {
  if (control instanceof FormGroup) {
    const group = control as FormGroup;

    Object.keys(group.controls).forEach((field) => {
      const c = group.controls[field];

      updateTreeValidity(c);
    });
  } else if (control instanceof FormArray) {
    const group = control as FormArray;

    group.controls.forEach((c) => {
      updateTreeValidity(c);
    });
  }

  control.updateValueAndValidity({ onlySelf: false });
}
